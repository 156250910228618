<template>
  <section class="content-section">
    <div class="container px-4 px-lg-5">

      <div class="row" v-if="!$store.state.connected">
        <div class="text-center">
          <p>Please connect to {{ $store.getters.config.chainName }}.</p>
          <button class="btn btn-primary" v-on:click="$store.dispatch('connectWallet')">Connect wallet</button>
        </div>
      </div>

      <div class="row gx-4 gx-lg-5" v-if="$store.state.connected">
        <div class="col-md-10 col-lg-8 mx-auto text-center">
          <h2 class="text-white mb-5">My $RATs</h2>
        </div>

        <div class="col-md-6 col-lg-6 mx-auto" v-if="$store.state.address">
            <div class="input-group mb-3 align-content-end">
              <span class="input-group-text iq">{{ $store.getters.config.power }}: {{ $store.state.stakePower }} {{ $store.getters.config.powerIcon }}</span>
              <button class="btn btn-outline-primary">
                {{ $store.state.stakeEarnings }} {{ $store.getters.config.currency }}
              </button>
              <button class="btn btn-primary" @click="claim()" v-if="$store.state.stakeEarnings > 0">
                Claim
              </button>
            </div>
            <!--div class="input-group mb-3 align-content-end" v-if="tokenEnabled">
              <span class="input-group-text">NXS</span>
              <button class="btn btn-outline-primary">
                {{ $store.state.nexusBalance }} NXS
              </button>
            </div-->
        </div>
      </div>
    </div>
    <div class="container text-center">
      <div class="row">
        <template v-for="nft in $store.state.wallet" :key="nft">
          <div v-if="!$store.state.nfts[nft].slot" class="col-lg-2 col-md-3 col-sm-6 rat shelve-tooltip clickable">
            <router-link v-bind:to="'/rat/id/' + $store.state.nfts[nft].id">
              <img v-bind:src="$store.state.nfts[nft].image" v-bind:class="'img-fluid nft'" />
            </router-link><br/>
            <span>
              {{$store.state.nfts[nft].metadata.name}}<br/>
              {{ $store.getters.config.powerIcon }} {{$store.state.nfts[nft].metadata.power}} (Base: {{ $store.getters.config.powerIcon }} {{$store.state.nfts[nft].metadata.basePower}})
            </span>
            <div class="shelve-tooltiptext wide">
              <template v-for="attribute in $store.state.nfts[nft].metadata.attributes" :key="attribute">
                <small v-if="attribute.power > 0">
                  {{attribute.trait_type}}: {{attribute.value}} {{ $store.getters.config.powerIcon }} {{attribute.power}}<br/>
                </small>
              </template>
              Base {{ $store.getters.config.power }}: {{ $store.getters.config.powerIcon }} {{$store.state.nfts[nft].metadata.basePower}}<br/>
              Total {{ $store.getters.config.power }}: {{ $store.getters.config.powerIcon }} {{$store.state.nfts[nft].metadata.power}}
            </div>
          </div>
        </template>
        <template v-if="$store.state.connected && $store.state.wallet.length === 0">
          <div class="text-center">
            <button class="btn btn-primary" v-on:click="$store.dispatch('loadWallet')">Load wallet</button>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'App',
  watch: {
    '$store.state.address': function() {
      this.$store.dispatch('loadWallet');
    }
  },
  mounted() {
    //this.$store.dispatch('loadWallet');
  },
  methods: {
    claim: async function() {
      await (await this.$store.getters.freshStakeContract.harvest()).wait();
      window.location.reload();
    }
  }
}
</script>